// importing ref and watcheffect from vue
import {ref , watchEffect} from 'vue'
// importing projectFirestore from config file
import {ProjectFirestore} from '@/firebase/config'

// function that takes collection name and condition (query) as an argument
// where query is optional
const getCollection = (collection , query) => {

    const documents = ref(null)
    const error = ref(null)

    let documentsref = ProjectFirestore.collection(collection).orderBy('createdAt')

    if(query){
        documentsref =  documentsref.where(...query)
    }

    const unsub = documentsref.onSnapshot((snap) => {
        
        let results = []
        
        snap.docs.forEach((doc) => {
            
            doc.data().createdAt && results.push({...doc.data() , id: doc.id})
        })

        documents.value = results
        error.value = null
    }, err =>{
        console.log(err.message)
        error.value = "could not able to fetch documents"
        documents.value = null
    })

    watchEffect((onInvalidate) => {
        onInvalidate(() => unsub())
    })

    return{documents , error}
}

export default getCollection