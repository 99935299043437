import {ref} from 'vue'
import {ProjectFirestore} from '@/firebase/config'

const useCollection = (collection) => {

    const error = ref(null)
    const isPending = ref(false)

    const addDoc = async (doc) => {
        error.value = null
        isPending.value = true

        try{
            const res = await ProjectFirestore.collection(collection).add(doc)
            error.value = null
            isPending.value = false
            return res
        }catch(error){
            console.log(error.message)
            error.value =  "could not add package"
            isPending.value = false
        }
    }

    return {error , isPending , addDoc}
}

export default useCollection