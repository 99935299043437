<template>
    <!-- footer -->
    <footer class="section footer" id="footer">
        <div class="footer-container container">
    
          <div class="footercontent">
            <a href="#" class="logo-content flex">
              <!-- <i class='bx bx-coffee logo-icon'></i> -->
              <span class="logo-text">Aasil-way</span>
            </a>
            <p class="footerDescription">At Aasil-way LLC, we are dedicated to providing a wide range of essential services to meet your needs. From supplying fresh water to offering potable water delivery, salt water supply, our goal is to ensure your convenience and satisfaction.<br/><br/> To explore how we can help you, please get in touch. You'll find our contact details aside.</p>
            <div class="footerLocation flex">
              <i class="bx bx-map map-icon"></i>
              <div class="loactionText">
                304, Madiha building, Deira, Dubai
              </div>
            </div>
          </div>
    
          <div class="footerlinkcontent">
            <!-- 1 -->
            <ul class="footer-links">
              <h4 class="footerlinks-title">Contact us</h4>
              <li><a href="#" class="footerlink">Phone number: +971 52 439 2882</a></li>
              <li><a href="#" class="footerlink">email: ashfaqmalik78@gmail.com</a></li>
              <!-- <li><a href="#" class="footerlink">event Room</a></li> -->
              <!-- <li><a href="#" class="footerlink">creative studio</a></li> -->
              <!-- <li><a href="#" class="footerlink">custom Room</a></li> -->
            </ul>
            <!-- 2 -->
            <!-- <ul class="footer-links">
              <h4 class="footerlinks-title">facility</h4>
              <li><a href="#" class="footerlink">coffee</a></li>
              <li><a href="#" class="footerlink">beverages</a></li>
              <li><a href="#" class="footerlink">dishes</a></li>
            </ul> -->
            <!-- 3 -->
            <ul class="footer-links">
              <h4 class="footerlinks-title">services</h4>
              <li><a href="#" class="footerlink">Sweet water tanker supply</a></li>
              <li><a href="#" class="footerlink">Salt water tanker supply</a></li>
              <li><a href="#" class="footerlink">Swimming pool filling</a></li>
              <li><a href="#" class="footerlink">Irrigation water tanker</a></li>
              <li><a href="#" class="footerlink">Drinking water supply</a></li>
            </ul>
          </div>
    
        </div>
        <div class="footer_copyright">&#169; Aasil-way. All Rights Reserved</div>
      </footer>
    </template>
    
    <script>
    export default {
    
    }
    </script>
    
    <style scoped>
    .footer{
      padding: 4rem 2rem 3rem;
      background-color: var(--section-bg);
    }
    
    .footer .footerDescription,
    .footer .loactionText,
    .footer .map-icon{
      color: var(--textcolorlight);
    }
    
    .footer-container{
      display: grid;
      grid-template-columns: 2fr 2fr;
      column-gap: 7rem;
      align-items: center;
    }
    
    .container {
        max-width: 1030px;
        width: 100%;
        margin: 0 auto;
        padding: 0 3rem;
    }
    .footercontent{
      display: grid;
      row-gap: 3rem;
    }
    
    .footer .footerDescription,
    .footer .loactionText{
      text-align: left;
      font-size: var(--smallfont);
    }
    
    .footer .map-icon{
      font-size: 3rem;
      margin-right: 1rem;
    }

    .logo-text{
        color: white;
    }
    
    .footerlinkcontent{
      display: grid;
      grid-template-columns: repeat(2 , 1fr);
      justify-items: end ;
    }
    
    .footer-links{
      display: grid;
      row-gap: 1rem;
      font-weight: var(--font-medium);
      color: var(--whitecolor);
    }
    
    .footerlinks-title{
      text-transform: capitalize;
    }
    
    .footerlink{
      font-size: var(--smallfont);
      color: rgba(255, 255, 255, 0.5);
      transition: var(--trans-0-3);
      text-transform: capitalize;
    }
    
    .footerlink:hover{
      color: rgba(255, 255, 255, 0.8);
    }
    
    .footer_copyright{
      max-width: 970px;
      margin: 0 auto;
      text-align: center;
      padding-top: 3rem;
      margin-top: 3rem;
      font-size: var(--smallfont);
      word-spacing: 0.5rem;
      color: rgba(255, 255, 255, 0.5);
      border-top: 2px solid var(--textcolorlight);
    }
    
    @media screen and (max-width : 768px){
      .footer-container{
        column-gap: 1rem;
      }
    }
    
    @media screen and (max-width : 600px){
      .footer-container{
        grid-template-columns: 1fr;
        row-gap: 6rem;
      }
      .footercontent{
        justify-items: center;
      }
      .footerlinkcontent{
        justify-items: center;
        grid-template-columns: 1fr;
      }
      .footer-links{
        margin-bottom: 3rem;
      }
      
    }
    
    @media screen and (max-width : 330px){
      .footerlinkcontent{
        column-gap: 2rem;
      }
    }
    
    </style>