import firebase from 'firebase/app'
import 'firebase/firestore'
// import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBKcJ58WlKdib8pZWvp5LQqbcIALsYbF_k",
    authDomain: "assil-way.firebaseapp.com",
    projectId: "assil-way",
    storageBucket: "assil-way.appspot.com",
    messagingSenderId: "711411195359",
    appId: "1:711411195359:web:851b005b4c6b6e6220b86a"
  };

//   init firebase
firebase.initializeApp(firebaseConfig)

// init services
const ProjectFirestore = firebase.firestore()
// authentication will not be used in this project, if there is a need in future will implement it.
// const projectAuth = firebase.auth() 
const ProjectStorage = firebase.storage();

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp

// exporting so it can be reused
export {ProjectFirestore, ProjectStorage, timestamp}