import {ref} from 'vue'
import { ProjectStorage } from '@/firebase/config'

const useStorage = () => {

    const error = ref(null)
    const url = ref(null)
    const filepath = ref(null)

    const uploadImage = async (file) => {
        filepath.value = `covers/${file.name}`
        const storageRef = ProjectStorage.ref(filepath.value)

        try{
           const res = await storageRef.put(file)
           url.value = await res.ref.getDownloadURL()
        }catch(e){
            console.log(e.message)
            error.value = e.message
        }
    }

    const deleteimage = async (path) => {
        const storageRef = ProjectStorage.ref(path)

        try{
            await storageRef.delete()
        }catch(err){
            console.log(err.message)
            error.value = ''
        }
    }

    return{error , url , filepath ,uploadImage ,deleteimage}
}

export default useStorage