<template>
    <!-- creating packages -->
      <div class="createpackage">
        <h2 class="title">Create Service</h2>
        <form @submit.prevent="handleSubmit">
            
            <!-- <label>Name</label>
            <input type="text" required v-model="name" /> -->
  
            <label>title</label>
            <input type="text" required v-model="title" />
        
            <label>Description</label>
            <textarea v-model="description" required></textarea>
    
            <!-- <label>Price</label>
            <input type="number" required v-model="price" /> -->
            
            <label>Upload package image</label>
            <input type="file" required @change="handlechange"/>
            <div v-if="fileError" class="error">{{fileError}}</div>
            
            <label>Includes (press alt + comma to add)</label>
            <input type="text" v-model="tempIncludes" @keyup.alt="addincludes" />
            <div v-for="include in Includes" :key="include" class="pill">
                <span @click="Deleteinclude(include)" >{{include}}</span>
            </div>
            
            
            <!-- <div>
                <label>why this trip (press alt + comma to add)</label>
                <input type="text" v-model="tempwhy" @keyup.alt="addwhy" />
                <div v-for="x in why" :key="x" class="pill">
                    <span @click="Deletewhy(x)" >{{x}}</span>
                </div>
            </div> -->
    
            <!-- <div class="featured">
                <input type="checkbox" v-model="featuredpackage">
                <label>Do you want this package to be featured ?</label>
            </div> -->
    
            <div class="error"></div>
            <div class="submit" v-if="!isPending">
                <button>Create a Service</button>
            </div>
            <div class="submit" v-else>
                <button disabled>Saving...</button>
            </div>
        
        </form>
      
      </div>
    
    </template>
    
    <script>
    import {ref} from 'vue'
    import useStorage from '@/composables/useStorage'
    import useCollection from '@/composables/useCollection'
    import {timestamp} from '@/firebase/config'
    import {useRouter} from 'vue-router'
    
    export default {
        setup(){
    
            const title = ref(null)
            // const name = ref(null)
            const description = ref(null)
            const tempIncludes = ref(null)
            // const price = ref(null)
            // const featuredpackage = ref(false)
            const Includes = ref([])
            const file = ref(null)
            const fileError = ref(null)
            const isPending = ref(false) 
            const router = useRouter()
    
            const {error , addDoc} = useCollection('services')
            const {url , filepath ,uploadImage}  = useStorage()
    
            const allowedtypes = ['image/png' , 'image/jpeg']
    
            const addincludes = (e) => {
                console.log(e)
                if(e.key === ',' && tempIncludes.value){
                    if(!Includes.value.includes(tempIncludes.value)){
                        Includes.value.push(tempIncludes.value)
                    }
                    tempIncludes.value = ''
                }
            }
    
          //   const addwhy = (e) => {
          //       console.log(e)
          //       if(e.key === ',' && tempwhy.value){
          //           if(!why.value.includes(tempwhy.value)){
          //               why.value.push(tempwhy.value)
          //           }
          //           tempwhy.value = ''
          //       }
          //   }
    
            const Deleteinclude = (include) => {
                Includes.value = Includes.value.filter((item) => {
                    return include !== item
                })
            }
    
          //   const Deletewhy = (x) => {
          //       why.value = why.value.filter((item) => {
          //           return x !== item
          //       })
          //   }
    
            const handleSubmit = async () => {
                if(file.value){
    
                    isPending.value = true
                    
                    await uploadImage(file.value)
  
                    const res = await addDoc({
                        title : title.value,
                        description : description.value,
                        // price : price.value,
                        // Featureds : featuredpackage.value,
                        orders : [],
                        // userName : name.value,
                        Includes : Includes.value,
                        coverURL : url.value,
                        filepath : filepath.value,
                        createdAt : timestamp()
                    })
    
                    isPending.value = false
    
                    if(!error.value){
                        router.push({name : 'home'})
                        console.log('done')
                    }
                }
            }
    
            const handlechange = (e) => {
                const selected = e.target.files[0]
                console.log(selected)
    
                if(selected && allowedtypes.includes(selected.type)){
                    file.value = selected
                    fileError.value = null
                }else{
                    file.value = null
                    fileError.value = "please select image type ( png , jpeg )"
                }
            }
            
    
            return{title,description,tempIncludes,isPending,addincludes,Includes,Deleteinclude,handleSubmit,fileError,handlechange}
        }
    }
    </script>
    
    <style scoped>
    .createpackage{
      margin-top: 10rem;
    }
  
    form {
        max-width: 600px;
        max-height: auto;
        margin: 40px auto;
        /* background: white; */
        text-align: left;
        padding: 40px;
        /* border-radius: 10px; */
    
        /* padding: 40px 40px 0px; */
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(247,247,247,1) 100%);
        border : 2px solid rgb(224, 224, 224);
        border-radius: 2%;
      }
    
      input::placeholder , textarea::placeholder{
        background: rgba(247,247,247,1);
      }
    
    label {
        color: #aaa;
        display: inline-block;
        margin: 25px 0 15px;
        font-size: 0.6em;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: bold;
    }
    
    input , textarea{
        display: block;
        padding: 10px 6px;
        width: 100%;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid #ddd;
        color: #555;
    }
    
    textarea {
        height: 120px;
    }
    
    input[type='checkbox']{
        display: inline-block;
        margin: 0 10px 0 0;
        width: 16px;
        position: relative;
        top: 2px;
    }
    
    .pill {
        display: inline-block;
        margin: 20px 10px 0 0;
        padding: 6px 12px;
        background: #eee;
        border-radius: 20px;
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: bold;
        color: #777;
        cursor: pointer;
    }
    
      button {
        border-radius : 20px;
        border : 2px solid #0583D2;
        background : #0583D2;
        width: 100%;
        color : #fafafa;
        font-size : 16px;
        margin-top : 20px;
        padding : 12px 45px;
        letter-spacing: 1px;
        text-transform : capitalize;
        transition: transform 80ms ease-in;
        transition: all 0.3s linear;
        border-color : #0583D2;
      }
    
      button:hover{
        background : transparent;
        color : #0583D2;
    }
    
    button:focus{
        outline : none;
    }
    
      .submit {
        text-align: center;
      }
    
    h2{
        text-align : center;
        color : #555;
        letter-spacing : 3px;
    }
  
    @media screen and (max-width : 700px){
      form{
            max-width: 550px;
        }
    }
    @media screen and (max-width : 575px){
        form{
            max-width: 425px;
        }
        input[type='checkbox']{
            margin: 10px 2px 0 0;
        }
    }
    @media screen and (max-width : 450px){
        form{
            max-width: 350px;
        }
    }
    @media screen and (max-width : 400px){
        form{
            max-width: 300px;
        }
        button{
            padding : 10px 30px;
            font-size: 12px;
        }
    }
    @media screen and (max-width : 375px){
        form{
            padding : 20px;
            margin : 40px auto;
        }
    }
    @media screen and (max-width : 325px){
        form{
            max-width: 250px;
        }
        button{
            padding : 10px 25px;
        }
        .title{
            font-size: 1em;
        }
    }
    </style>