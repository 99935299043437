<template>
    <!-- <h2 class="title">All Packages</h2> -->
    <!-- <div v-for="service in packages" :key="service.id">
        <router-link :to="{name : 'Packagedetails' , params:{id: service.id}}">
        <div class="single">

            <div class="thumbnail">
                <img :src='service.coverURL' />
            </div>

            <div class="info">
                <h3>{{service.title}}</h3> 
                <p>created by {{service.userName}}</p>
            </div>

            <div class="include-number">
                <p>{{service.Includes.length}} includes</p>
            </div>

        </div>
        </router-link>
    </div>   -->
    <div class="packagesContainer">
        <h2 class="title">Our services</h2>

        <!-- <div class="search-box" ref="searchbox">
            <input type="text" placeholder="search for safari" v-model="search" />
            <span class="icon">
                <i class="uil uil-search search-icon" @click="searchicon"></i>
            </span>
            <i class="uil uil-times close-icon" @click="closeicon"></i>
        </div> -->

        <div class="cardcontainer">
            <div class="card">
                <!-- premium package -->
                <!-- <div class="packagecard" v-for="prem in Premium" :key="prem.id">
                    <div class="imgContainer"><img class="packageimage" :src='prem.coverURL'/></div>
                    <div class="productDetails">
                        <h2>{{prem.title}}</h2> -->
                        <!-- <p>{{ snippets }}</p> -->
                        <!-- <div class="price">From AED {{prem.price}}</div>
                        <div class="buttons">
                            <router-link :to="{ name: 'PremiumPackageDetails' , params:{id : prem.id}}">
                                <button>Explore</button>
                            </router-link>
                        </div>
                    </div>
                </div> -->
                <!-- services -->
                <div class="packagecard" v-for="service in filteredservices" :key="service.id"><!--for v-for -->
                    <div class="imgContainer"><img class="packageimage" :src='service.coverURL' /></div>
                    <div class="productDetails">
                        <h2>{{service.title}}</h2>
                        <p>{{ service.description }}</p>
                        <!-- <div class="price">From AED {{service.price}}</div> -->
                        <ul v-for="Inc in service.Includes" :key="Inc">
                            <!-- displaying includes -->
                            <li>{{Inc}}</li>
                        </ul>


                        <!-- <div class="buttons">
                            <router-link :to="{name : 'Packagedetails' , params:{id: service.id}}">
                                <button>Explore</button>
                            </router-link>
                        </div> -->


                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
import {ref , computed} from 'vue'
// import getCollection from '@/composables/getCollection'
export default {
    props : ['services'],
    setup(props){


        const searchbox = ref(null)

        const search = ref('')

        const filteredservices = computed(() => {
            return props.services.filter((service) => service.title.includes(search.value))
        })

        // const filteredJobs = computed(() => {
        //     return props.packages.filter((job) => job.title.includes(search.value))
        // })

        // const snippets = computed(() => {
        //     return props.services.forEach((service) => {
        //         service.description.substring(0, 50) + '...'
        //     })
        // })

        const searchicon = () => {
            searchbox.value.classList.add('open')
        }

        const closeicon = () => {
            searchbox.value.classList.remove('open')
        }

        return{searchbox,searchicon,closeicon,filteredservices,search}
    }
}
</script>

<style scoped>
.packagesContainer{
    display : flex;
    flex-direction: column;
    justify-content : flex-end;
    margin-bottom: 40px;
}

/* search box */
.search-box{
    position: relative;
    height: 40px;
    max-width: 60px;
    /* margin-left: auto; */
    justify-content: flex-end;
    width: 100%;
    margin : 10px 40px 10px auto;
    border-radius: 6px;
    background-color: #fff;
    border: 2px solid #184A45FF;
    transition : all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55)
}

.search-box.open{
    max-width: 180px;
    padding: 0 15px 0 65px;
}

/* styling for title  */
.title{
    text-align: center;
    line-height: 15px;
    text-transform: capitalize;
    position: relative;
    margin: 2rem auto;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
	word-spacing: 0.4rem;
}
.title::after{
    content: '';
    position: absolute;
    width: 7rem;
    height: 5px;
    border-radius: 5px;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    background: #0583D2;
}

ul li{
    list-style: disc;
    margin-left: 2.5rem;
    color: #0583D2;
}

input{
    position: relative;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;
    color: #333;
    background-color: #fff;
}

.icon{
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 60px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    background-color: #fff;
}

.search-icon,
.close-icon{
    position: absolute;
    top: 50%;
    font-size: 30px;
    transition : all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.search-icon{
    color: #184A45FF;
    transform:  translateY(-50%) rotate(90deg);
}

.search-box.open .search-icon{
    transform:  translateY(-50%) rotate(0);
}

.close-icon{
    right: -5px;
    color: #184A45FF;
    padding: 5px;
    opacity: 0;
    pointer-events: none;
    transform:  translateY(-50%);
}

.search-box.open .close-icon{
    opacity: 1;
    pointer-events: auto;
    transform:  translateY(-50%) rotate(180deg);
}

.cardcontainer{
    display : grid;
}

.card{
    display : grid;
    grid-template-columns: repeat(auto-fit , minmax(300px , 1fr));
    padding : 20px;
    grid-gap : 40px;
    place-items: center center;
    position: relative;
    background-color:transparent ;
}

.packagecard{
     box-shadow: 6px 7px 5px rgba(50,50,50,0.05);
     border-radius: 10px;
     /* width: 300px; */
}

.packagecard:hover {
    box-shadow: 6px 7px 5px rgba(50,50,50,0.10);
    transform: scale(1.02);
    transition: all ease 0.2s;
  }
.imgContainer{
    position : relative;
    /* clip-path : polygon(0 0 , 100% 0 , 100% 85% , 0 100%); */
}

.imgContainer img{
    width : 100%;
    height: 200px;
    display : block;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
}
.productDetails{
    /* width: 300px;
    height: 190px; */
    background-color: #fff;
    /* border-radius: 0 40% 0 0; */
    padding : 0.8em;
    border-radius: 0px 0px 10px 10px;
}
.productDetails h2{
    text-transform: capitalize;
    color: #0583D2;
    margin-bottom: 10px;
    word-spacing: 5px;
}
.productDetails p{
    font-size: 15px;
    color: rgb(80, 80, 80);
    margin: 10px auto ;
    line-height: 30px;
}
.price{
    font-weight: 700;
    color: rgb(189, 189, 189);
    font-size: 12px;
}
.buttons{
    display: flex;
    justify-content: flex-start;
    margin: 10px auto 5px;
}
button{
    /* border-radius : 20px; */
    border : 2px solid #184A45FF;
    background : transparent;
    color : #000;
    font-size : 12px;
    font-weight : bold;
    padding : 12px 45px;
    letter-spacing: 1px;
    text-transform : capitalize;
    transition: transform 80ms ease-in;
    transition: all 0.3s linear;
    border-color : #184A45FF;
}

/* button:active{
    transform : scale(0.95);
} */

button:hover{
    background : #184A45FF;
    color : #fff;
}

button:focus{
    outline : none;
}

@media screen and (max-width : 425px) {
    .card{
       grid-template-columns: repeat(auto-fit , minmax(280px , 1fr)); 
    }
    .search-box.open{
        max-width: 80px;
    }
    .search-box{
        margin: 25px 40px 15px auto;
        height: 25px;
        max-width: 40px;
    }

    .icon{
        width: 40px;
    }

    .search-icon, .close-icon{
        font-size: 18px;
    }
    
}
@media screen and (max-width : 400px) {
    .card{
       grid-template-columns: repeat(auto-fit , minmax(190px , 1fr)); 
    }
    .search-box{
        margin: 25px 25px 15px auto;
    }
}
</style>