<template>
  <Navbar />
  <router-view/>
  <Footer />

  <div id="WHATSAPP">
    <!-- by clicking whatsapp logo it will take to the chat room of the number provided -->
    <span id="WHATSAPP-LOGO"><a href="https://wa.me/+971524392882"><i class="uil uil-whatsapp"></i></a></span>
  </div>

  <a href="#" ref="scrollupdate" class="scrollUp-btn flex">
    <i class="bx bx-up-arrow-alt scrollUp-icon"></i>
  </a>
</template>

<script>

import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { ref, onMounted } from 'vue';
export default {
  components: {Navbar, Footer},
  setup(){
    const scrollupdate = ref(null)

    const updateScroll = () => {
        const scrollPosition = window.scrollY
        // console.log(scrollPosition)
        if(scrollPosition > 250){
          scrollupdate.value.classList.add('scrollUpbtn-active')
          return;
        }else{
          scrollupdate.value.classList.remove('scrollUpbtn-active')
          return;
        }
    }

    onMounted (() => {
        window.addEventListener("scroll", updateScroll)
    })

    return{updateScroll , scrollupdate}
  }
}
</script>


<style scoped>
.scrollUp-btn{
  position: fixed;
  bottom: -30%;
  right: 3rem;
  height: 3rem;
  width: 3rem;
  border-radius: 4px;
  font-size: 2rem;
  color: var(--primarycolorhover);
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  background-color: var(--whitecolor);
  justify-content: center;
  z-index: var(--z-overlay);
  transition: var(--trans-0-3);
}

.flex {
    display: flex;
    align-items: center;
    column-gap: 0.6rem;
}

.scrollUpbtn-active{
  bottom: 3rem;;
}

.scrollUp-icon{
  opacity: 0.7;
  transition: var(--trans-0-3);
}

.scrollUp-btn:hover .scrollUp-icon{
  opacity: 1;
}

/* styling for whatsapp logo */
#WHATSAPP{
  position: fixed;
  background-color: #184A45FF;
  bottom: 20px;
  left: 20px;
  height: 50px;
  width: 50px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  box-shadow: 0px 0px 10px rgba();
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 100;
}

#WHATSAPP-LOGO a i{
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  border-radius: 50%;
  text-decoration: none;
  font-size: 25px;
  color: #fff;
}
</style>
