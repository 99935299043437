<template>
  
    <div class="reviews">
      <h2 class="title">Would Appreciate your reviews</h2>
      <form @submit.prevent="handleSubmit">
  
          <label>Your Name</label>
          <input type="text" required v-model="name" />

          <label>Email Address</label>
          <input type="email" required v-model="email" />

          <label>Package Name</label>
          <input type="text" required v-model="service" placeholder="Will be filled by service provider"/>
      
          <label>Comment</label>
          <textarea v-model="review" required></textarea>
        
          <label>Ratings</label>
          <div class="rating">
            
            <input type="checkbox" name="star1" value="5" id="star1" v-model="ratings" />
            <label for="star1"></label>

            <input type="checkbox" name="star2" value="4" id="star2" v-model="ratings"/>
            <label for="star2"></label>
            
            <input type="checkbox" name="star3" value="3" id="star3" v-model="ratings"/>
            <label for="star3"></label>
            
            <input type="checkbox" name="star4" value="2" id="star4" v-model="ratings"/>
            <label for="star4"></label>
            
            <input type="checkbox" name="star5" value="1" id="star5" v-model="ratings"/>
            <label for="star5"></label>
          
        </div>
  
          <div class="error"></div>
          <div class="submit" v-if="!isPending">
              <button>submit</button>
          </div>
          <div class="submit" v-else>
              <button disabled>submitting</button>
          </div>

          <!-- <h3>{{ratings}}</h3> -->
      
      </form>
    
    </div>
  
</template>
  
  <script>
  import {ref} from 'vue'
  import useCollection from '@/composables/useCollection'
  import {timestamp} from '@/firebase/config'
  import {useRouter} from 'vue-router'
  
  export default {
      setup(){
  
          const name = ref(null)
          const email = ref(null)
          const service = ref(null)
          const review = ref(null)
          const ratings = ref([])
          const isPending = ref(false) 
          const router = useRouter()
  
          const {error , addDoc} = useCollection('reviews')
  
          const handleSubmit = async () => {

            console.log(name.value , email.value , service.value , review.value , ratings.value)

              if(ratings.value){
  
                  isPending.value = true
                  
                  const res = await addDoc({
                      name : name.value,
                      email : email.value,
                      package : service.value,
                      review : review.value,
                      ratings : ratings.value,
                      createdAt : timestamp()
                  })
  
                  isPending.value = false
  
                  if(!error.value){
                      router.push({name : 'home'})
                      console.log("done")
                  }
              }
          }
  
  
          return{handleSubmit , isPending , name , email ,review , service , ratings }
      }
  }
  </script>
  
  <style scoped>
  .reviews{
    margin-top: 10rem;
  }
  form {
      max-width: 600px;
      max-height: auto;
      margin: 40px auto;
      /* background: white; */
      text-align: left;
      padding: 40px;
      /* border-radius: 10px; */
  
      /* padding: 40px 40px 0px; */
      background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(247,247,247,1) 100%);
      border : 2px solid rgb(224, 224, 224);
      border-radius: 2%;
    }
  
    input::placeholder , textarea::placeholder{
      background: rgba(247,247,247,1);
    }

input::placeholder{
    background: none;
    color: #d9d9d9;
    text-transform: none;
}
  
  label {
      color: #aaa;
      display: inline-block;
      margin: 25px 0 15px;
      font-size: 0.6em;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: bold;
  }
  
  input , textarea{
      display: block;
      padding: 10px 6px;
      width: 100%;
      box-sizing: border-box;
      border: none;
      border-bottom: 1px solid #ddd;
      color: #555;
  }
  
  textarea {
      height: 120px;
  }
  
  input[type='checkbox']{
      display: inline-block;
      margin: 0 10px 0 0;
      width: 16px;
      position: relative;
      top: 2px;
  }
  
  .pill {
      display: inline-block;
      margin: 20px 10px 0 0;
      padding: 6px 12px;
      background: #eee;
      border-radius: 20px;
      font-size: 12px;
      letter-spacing: 1px;
      font-weight: bold;
      color: #777;
      cursor: pointer;
  }
  
    button {
      border-radius : 20px;
      border : 2px solid #0583D2;
      background : #0583D2 ;
      width: 100%;
      color : #fafafa;
      font-size : 16px;
      margin-top : 20px;
      padding : 12px 45px;
      letter-spacing: 1px;
      text-transform : capitalize;
      transition: transform 80ms ease-in;
      transition: all 0.3s linear;
      border-color : #0583D2 ;
    }
  
    button:hover{
      background : transparent;
      color : #0583D2;
  }
  
  button:focus{
      outline : none;
  }
  
    .submit {
      text-align: center;
    }
  
  h2{
      text-align : center;
      color : #555;
      letter-spacing : 3px;
  }
  .rating{
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateY(180deg);
  }
  .rating input{
    display: none;
  }
    
  .rating label{
    display: block;
    cursor: pointer;
    width: 10%;
    background: none;
  }
  /* .rating label::before{
    content: '\f005';
    font-family: fontAwesome;
    position: relative;
    display: block;
    font-size: 50px;
    color: #101010;
  } */
  .rating label::after{
    content: '\f005';
    font-family: fontAwesome;
    position: relative;
    display: block;
    /* top: 50%; */
    opacity: 1;
    font-size: 25px;
    transition: 0.5s;
    /* text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); */
    color: #101010;
  }

  .rating label:hover:after,
  .rating label:hover ~ label:after,
  .rating input:checked ~ label::after{
    opacity: 1;
    color: #0583D2;
  }
  @media screen and (max-width : 700px){
    form{
          max-width: 550px;
      }
  }

  @media screen and (max-width : 575px){
      form{
          max-width: 425px;
      }
      input[type='checkbox']{
          margin: 10px 2px 0 0;
      }
  }
  @media screen and (max-width : 550px){
    .reviews .title{
        line-height: 35px;
    }
  }
  @media screen and (max-width : 450px){
      form{
          max-width: 350px;
      }
      .rating{
        justify-content: space-evenly;
      }
  }
  @media screen and (max-width : 400px){
    form{
          max-width: 300px;
      }
  }
  @media screen and (max-width : 375px){
      button{
          padding : 10px 30px;
          font-size: 12px;
      }
      form{
          padding : 20px;
          margin : 40px auto;
      }
  }
  @media screen and (max-width : 325px){
      form{
          max-width: 250px;
      }
      button{
          padding : 10px 25px;
      }
      .title{
          font-size: 1em;
      }
  }
  </style>