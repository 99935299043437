<template>
  <div class="home" id="home">
    <!-- <div class="home-content"> -->
      <swiper
        :slidesPerView="1"
        :grabCursor = "true"
        :loop="true"
        :pagination="{
          clickable: true,
        }"
        :navigation="true"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide>
          <img src="@/assets/Images/sweetwater.jpg" alt="image 1"/>
          <div class="home-details">
            <div class="hometext">
              <h4 class="homesubtitle">Bringing Pure Water Right to Your Doorstep.</h4>
              <h2 class="hometitle">Convenient Freshwater Delivery Service</h2>
            </div>
            <button class="btn">explore</button>
          </div>
        </swiper-slide>

        <swiper-slide>
          <img src="@/assets/Images/TSE_WATER_TANKER.jpg" alt="image 2"/>
          <div class="home-details">
            <div class="hometext">
              <h4 class="homesubtitle">Ensuring Efficient Watering for Your Fields and Gardens.</h4>
              <h2 class="hometitle">Irrigation Water Tanker Service</h2>
            </div>
            <button class="btn">explore</button>
          </div>
        </swiper-slide>

        <swiper-slide><img src="@/assets/Images/swimmingpoolfilling.jpg" alt="image 3"/>
          <div class="home-details">
            <div class="hometext">
              <h4 class="homesubtitle"> Ensuring Your Swimming Pool Stays Crystal Clear and Refreshing.</h4>
              <h2 class="hometitle">Pool Water Refilling</h2>
            </div>
            <button class="btn">explore</button>
          </div>
        </swiper-slide>

        <!-- <swiper-slide>Slide 4</swiper-slide>
        <swiper-slide>Slide 5</swiper-slide>
        <swiper-slide>Slide 6</swiper-slide>
        <swiper-slide>Slide 7</swiper-slide>
        <swiper-slide>Slide 8</swiper-slide>
        <swiper-slide>Slide 9</swiper-slide> -->
        <!-- <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-pagination"></div> -->
      </swiper>
    <!-- </div> -->
  </div>

  <!-- about -->
  <div class="section about" id="about">
    <div class="aboutcontent container">

      <div class="about-imagecontent">
        <img src="@/assets/Images/irrigation_water_tanker.jpg" alt="about image" class="about-img" />
        <div class="image-abouttext">
          <i class="bx bx-heart heart-icon flex"></i>
          <p class="about-description">Your top choice for tailored water tanker services in the UAE.</p>
        </div>
      </div>

      <div class="about-details">
        <div class="about-text">
          <h4 class="content-subtitle"><i>our Water Supply service</i></h4>
          <h2 class="content-title">We provide a variety of water supply services on time.</h2>
          <p class="content-description">Aasil-way offers customizable water tanker services tailored to your specific needs. When you're in search of a water tanker in Dubai, Sharjah, or Ajman, you'll discover a wide range of options available to you. We have a large fleet of water tanker services, including sweet and salt water choices, swimming pool filling, irrigation water tankers, and underground water tank filling ensuring we're your number one pick for water tanker solutions in the region.</p>

          <ul class="about-lists flex">
            <li class="about-list">Sweet water</li>
            <li class="about-list dot">.</li>
            <li class="about-list">Pool filling</li>
            <li class="about-list dot">.</li>
            <li class="about-list">Irrigation water tanker</li>
            <!-- <li class="about-list dot">.</li> -->
            <!-- <li class="about-list">Dinners</li>
            <li class="about-list dot">.</li>
            <li class="about-list">Private</li> -->
          </ul>
        </div>

        <!-- <div class="aboutbtn flex">
          <router-link :to="{name: 'about'}">
            <button class="btn">About us</button>
          </router-link>
          <a href="#" class="about-link flex">
            <span class="link-text">see more</span>
            <i class="bx bx-right-arrow-alt about-arrowicon"></i>
          </a>
        </div> -->

      </div>

    </div>
  </div>

  <!-- services provided by aasil-way -->
  <div class="allpackages">
    <div v-if="error" class="error">could not fetch the data</div>
    <div v-if="services">
        <Listview :services='services'/>
    </div>
  </div>

  <!-- featured menu -->
  <!-- <div class="section menu" id="menu">
    <div class="menuContainer container">

      <div class="menu-text">
        <h4 class="featuredSubtitle"><i>Our menu</i></h4>
        <h2 class="featuredTitle">Our featured menu</h2>
        <p class="featuredDescription">Our featured menu items can vary greatly depending on the establishment and cuisine. Our establishments also feature seasonal menus that change throughout the year to incorporate local and seasonal ingredients.</p>
      </div>

      <div class="menuContents">
        <div class="menuitems"> -->
          <!-- 1 -->
          <!-- <div class="menuitem flex">

            <img src="@/assets/Images/about1.jpeg" alt="featured1" class="menuimg" />

            <div class="menuitemDetails">
              <h4 class="menuitemTopic">Americano nice coffee</h4>
              <p class="menuitemDescription">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi, quibusdam. </p>
            </div>

            <div class="menuitemPrice flex">
              <span class="discountprice">$10.00</span>
              <span class="realprice">$15.00</span>
            </div>

          </div> -->
          <!-- 2 -->
          <!-- <div class="menuitem flex">

            <img src="@/assets/Images/about1.jpeg" alt="featured1" class="menuimg" />

            <div class="menuitemDetails">
              <h4 class="menuitemTopic">Americano nice coffee</h4>
              <p class="menuitemDescription">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi, quibusdam. </p>
            </div>

            <div class="menuitemPrice flex">
              <span class="discountprice">$10.00</span>
              <span class="realprice">$15.00</span>
            </div>

          </div> -->
          <!-- 3 -->
          <!-- <div class="menuitem flex">

            <img src="@/assets/Images/about1.jpeg" alt="featured1" class="menuimg" />

            <div class="menuitemDetails">
              <h4 class="menuitemTopic">Americano nice coffee</h4>
              <p class="menuitemDescription">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi, quibusdam. </p>
            </div>

            <div class="menuitemPrice flex">
              <span class="discountprice">$10.00</span>
              <span class="realprice">$15.00</span>
            </div>

          </div> -->
          <!-- 4 -->
          <!-- <div class="menuitem flex">

            <img src="@/assets/Images/about1.jpeg" alt="featured1" class="menuimg" />

            <div class="menuitemDetails">
              <h4 class="menuitemTopic">Americano nice coffee</h4>
              <p class="menuitemDescription">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi, quibusdam. </p>
            </div>

            <div class="menuitemPrice flex">
              <span class="discountprice">$10.00</span>
              <span class="realprice">$15.00</span>
            </div>

          </div>
          

        </div>

        <div class="timetable">
          <span class="timetopic">Categories time</span>
          <ul class="timelists"> -->
            <!-- 1 -->
            <!-- <li class="timelist flex">
              <span class="openday">sunday</span>
              <span class="opentime">Availabe any time</span>
            </li> -->
            <!-- 2 -->
            <!-- <li class="timelist flex">
              <span class="openday">monday</span>
              <span class="opentime">Availabe any time</span>
            </li> -->
            <!-- 3 -->
            <!-- <li class="timelist flex">
              <span class="openday">tuesday</span>
              <span class="opentime">Availabe any time</span>
            </li> -->
            <!-- 4 -->
            <!-- <li class="timelist flex">
              <span class="openday">wednessday</span>
              <span class="opentime">Availabe any time</span>
            </li> -->
            <!-- 5 -->
            <!-- <li class="timelist flex">
              <span class="openday">thursday</span>
              <span class="opentime">Availabe any time</span>
            </li> -->
            <!-- 6 -->
            <!-- <li class="timelist flex">
              <span class="openday">friday</span>
              <span class="opentime">Availabe any time</span>
            </li> -->
            <!-- 7 -->
            <!-- <li class="timelist flex">
              <span class="openday">saturday</span>
              <span class="opentime">Availabe any time</span>
            </li>

          </ul>
        </div>

      </div>

    </div>
  </div> -->

  <!-- instapost -->
  <!---------- insta post  ---------------->
  <!-- <div class="smallContainer section">
      <h2 class="instaTitle">Recent Instagram Post</h2>
      <div class="row2"> -->

        <!-- insta post 1 -->
        <!-- <div class="col_3_margin"> -->
          <!-- link to insta post of Burger Box -->
          <!-- <a href="https://www.instagram.com/p/CoAEJX2hg_h/"> -->
            <!-- image  -->
            <!-- <img src="../assets/images/recentinstapost1.jpeg" alt="instagram post 1">
          </a>
        </div> -->

        <!-- insta post 2 -->
        <!-- <div class="col_3_margin"> -->
          <!-- link to insta post of Burger Box -->
          <!-- <a href="https://www.instagram.com/p/ClEqKFkNnFi/"> -->
            <!-- image -->
            <!-- <img src="../assets/images/recentinstapost2.jpeg" alt="instagram post 2">
          </a>
        </div> -->

        <!-- insta post 3 -->
        <!-- <div class="col_3_margin"> -->
          <!-- link to insta post of Burger Box -->
          <!-- <a href="https://www.instagram.com/p/ClEp8aASd0C/"> -->
            <!-- image -->
            <!-- <img src="../assets/images/recentinstapost3.jpeg" alt="instapost post 3">
          </a>
        </div> -->

        <!-- insta post 4 -->
        <!-- <div class="col_3_margin"> -->
          <!-- link to insta post of Burger Box -->
          <!-- <a href="https://www.instagram.com/p/Ci200rdv2T3/"> -->
            <!-- image -->
            <!-- <img src="../assets/images/recentinstapost4.jpeg" alt="instagram post 4">
          </a>
        </div> -->

        <!-- insta post 5 -->
        <!-- <div class="col_3_margin"> -->
          <!-- link to insta post of Burger Box -->
          <!-- <a href="https://www.instagram.com/p/Ci27_a0PWUD/"> -->
            <!-- image -->
            <!-- <img src="../assets/images/recentinstapost5.jpeg" alt="instagram post 5">
          </a>
        </div> -->

        <!-- insta post 6 -->
        <!-- <div class="col_3_margin"> -->
          <!-- link to insta post of Burger Box -->
          <!-- <a href="https://www.instagram.com/p/CoAEZOjB8W5/"> -->
            <!-- image -->
            <!-- <img src="../assets/images/recentinstapost6.jpeg" alt="instagram post 6">
          </a>
        </div> -->

      <!-- </div>  -->
      <!-- closing of row -->
	<!-- </div>  -->
  <!--closing of small container --->

  <!-- review -->
  <section class="section review" id="review">
    <div class="reviewContainer container">

      <div class="reviewText">
        <h4 class="section_subtitle"><i>reviews</i></h4>
        <h2 class="section_title">What client says</h2>
        <p class="section_description">"At Aasil-way, we are dedicated to providing exceptional water supply solutions. But let our satisfied customers do the talking. Discover what our clients have to say about their experiences with us. We think their stories and feedback show how good we are.</p>
      </div>

      <div class="testtimonials swiper myswiper">
        <swiper
          :slidesPerView="1"
          :grabCursor = "true"
          :navigation="true"
          :modules="modules"
          class="mySwiper"
        >
        <swiper-slide class="testimonial-content flex" v-for="rev in  reviews" :key="rev.id">
          <img src="@/assets/Images/salt_water.jpg" alt="testi image" class="reviewimage" />
          <p class="review-quote">{{rev.review}}.</p>
          <i class="bx bxs-quote-alt-left quoteicon"></i>
          <div class="testimonialsPersonDetails flex">
            <span class="name">{{rev.name}}</span>
            <span class="job">{{rev.package}}</span>
            <span class="ratings" v-for="ra in rev.ratings" :key="ra">{{ra}} <i class='bx bxs-star staricon'></i>Ratings</span>
          </div>
        </swiper-slide>

        <!-- <swiper-slide class="testimonial-content flex">
          <img src="@/assets/images/aboutpage3.jpeg" alt="testi image" class="reviewimage" />
          <p class="review-quote">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint velit nihil animi culpa delectus quo modi quaerat qui aperiam esse. Officia libero non dolore quod recusandae perspiciatis, veritatis soluta animi.</p>
          <i class="bx bxs-quote-alt-left quoteicon"></i>
          <div class="testimonialsPersonDetails flex">
            <span class="name">munzir</span>
            <span class="job">full stack developer</span>
          </div>
        </swiper-slide> -->

      </swiper>
      </div>
    </div>
  </section>

  <!-- newsletter -->
  <div class="section newsletter" id="newsletter">
    <div class="newsletter_container container">

      <a href="#" class="logo-content flex">
        <!-- <i class='bx bx-coffee logo-icon'></i> -->
        <span class="logo-text">Aasil-way</span>
      </a>

      <p class="section_description">Stay informed and get exclusive updates by subscribing to our newsletter. Receive the latest water supply news and offers right in your inbox.</p>
    
      <div class="newsletter-inputbox" >
        <input type="email" placeholder="abc@gmail.com" class="newsletterInput" v-model="email" required/>
        <button class="btn newsletter-btn" @click.prevent="handlesubmit" v-if="!isPending">Subscribe</button>
        <button class="btn newsletter-btn" @click.prevent="handlesubmit" v-if="isPending">Submitting</button>
      </div>

      <div class="newsletter media flex">
          <a href=""><i class='bx bxl-facebook'></i></a>
          <a href=""><i class='bx bxl-twitter'></i></a>
          <a href=""><i class='bx bxl-instagram-alt'></i></a>
          <a href=""><i class='bx bxl-github'></i></a>
          <a href=""><i class='bx bxl-youtube'></i></a>
      </div>

    </div>
  </div>

</template>

<script>

import {ref} from 'vue'
import getCollection from '../composables/getCollection'
import useCollection from '@/composables/useCollection'
import {timestamp} from '@/firebase/config'
import Listview from '@/components/Listview.vue'


// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./style.css";

// import required modules
import { Pagination, Navigation } from "swiper";

export default {
  name: 'HomeView',
  components: {
    Swiper,
    SwiperSlide,
    Listview
  },
  setup() {

    const {documents : reviews} = getCollection('reviews')

    const {documents : services, error } = getCollection('services')

    // const {isPending, addDoc , error} = useCollection('newsLetter')

    // const email = ref(null)

    // const handlesubmit = async () => {
      
    //   const res = await addDoc({
    //     createdAt : timestamp(),
    //     email : email.value,
    //   })

    //   if(!error.value){
    //     console.log("submitted")
    //   }

    // }

    return { modules: [Pagination, Navigation], reviews , error, services};
  },
}
</script>

<style scoped>
.home{
  height: 100vh;
  width: 100%;
  /* background-color: red; */
}
.home .swiper {
  width: 100%;
  height: 100%;
}

.swiper{
    --swiper-theme-color: #0583D2;
}

.home .swiper-slide {
  position: relative;
  height: 100vh;
  width: 100%;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.home .swiper-slide::before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: var(--z-overlay);
}

.swiper-slide img {
  width: 100%;
  height: 100%;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
  /* --swiper-theme-color: #c87e4f; */
}

.swiper-button-next, .swiper-button-prev{
    display: flex;
    height: 5rem;
    width: 5rem;
    position: absolute;
    color: var(--whitecolor);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    transition: var(--trans-0-2);
}

.swiper-button-next:hover, .swiper-button-prev:hover{
  background-color: rgba(255, 255, 255, 0.3);
}
.swiper-button-next::before, .swiper-button-prev::before,
.swiper-button-next::after, .swiper-button-prev::after{
  font-size: 2rem;
}

.swiper-button-next{
  right: 16rem;
}

.swiper-button-prev{
  left: 16rem;
}


.home-details{
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: var(--z-fixed);
  transform: translate(-50% , -50%);
  display: grid;
  justify-items: center;
  width: 100%;
  row-gap: 4rem;
}

.homesubtitle{
  text-align: center;
  font-size: var(--normalfont);
  color: var(--whitecolor);
  text-transform: capitalize;
  letter-spacing: 2px;
  line-height: 30px;
  word-spacing: 5px;
  margin: 10px 0px;
}

.hometitle{
  text-align: center;
  font-size: var(--bigfont);
  color: var(--whitecolor);
  text-transform: capitalize;
  letter-spacing: 2px;
  word-spacing: 5px;
  font-weight: var(--font-semi-bold);
}

.pagination-slider .swiper-pagination-bullet{
  text-align: center;
  line-height: 40px;
  font-size: 24px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  width: 40px !important;
  height: 40px !important;
}
.pagination-slider .swiper-pagination-bullet-active {
  color: #fff;
  background: brown;
}

/* about */
.about .content-description,
.about .content-subtitle,
.about .content-title{
  text-align: left;
}

.aboutcontent{
  display: grid;
  grid-template-columns: repeat(2 , 1fr);
  column-gap: 7rem;
  padding: 0 5rem;
  align-items: center;
}

.about-details{
  display: grid;
  row-gap: 4rem;
}

.about-imagecontent{
  position: relative;
  max-width: 400px;
  height: 450px;
  border: 6px solid var(--section-bg);
}

.heart-icon{
  position: absolute;
  left: -1.9rem;
  top: 50%;
  height: 3rem;
  width: 3rem;
  color: var(--whitecolor);
  background-color: var(--section-bg);
  transform: translateY(-50%);
  justify-content: center;
  /* align-items: center; */
  border-radius: 50%;
}

.image-abouttext{
  position: absolute;
  bottom: 2rem;
  right: -5rem;
  padding: 2rem;
  max-width: 360px;
  width: 100%;
  text-align: center;
  border: 6px solid var(--section-bg);
  background-color: rgba(5, 131, 210, 0.8);
}
.about-description{
  color: #f2f2f2;
}

.featuredSubtitle,
.content-subtitle,
.section_subtitle{
  color: var(--primarycolor);
  font-size: var(--normalfont);
  letter-spacing: 1px;
  font-weight: var(--font-medium);
  text-align: center;
  text-transform: capitalize;
}

.featuredTitle,
.instaTitle,
.content-title,
.section_title{
  text-align: center;
  font-size: var(--h2font);
  font-weight: var(--font-medium);
  margin: 1rem 1rem 2rem 0rem;
}

.featuredDescription,
.content-description,
.section_description{
  text-align: center;
  margin-bottom: 2rem;
}

.section_description{
  padding: 0rem 16rem;
}

.image-abouttext .content-description{
  text-align: center;
  margin-bottom: 0;
}

.about-lists{
  column-gap: 1rem;
  align-items: baseline;
}

.about-list{
  font-weight: var(--font-medium);
  font-size: 1.8rem;
}

.about-list:nth-child(1){
  color: var(--primarycolor);
}

.about-list.dot{
  font-size: 2.5rem;
}

.aboutbtn{
  column-gap: 2rem;
}

.about-link .link-text,
.about-arrowicon{
  color: var(--textcolor);
  text-transform: uppercase;
}

.about-arrowicon{
  transition: var(--trans-0-3);
}

.about-link:hover .about-arrowicon{
  margin-left: 4px;
}

/* featured menu */
.menu{
  background-color: var(--section-bg);
}

.menu .menuContents{
  display: grid;
  grid-template-columns: 1.8fr 1fr;
  column-gap: 6rem;
  margin-top: 6rem;
}

.menuimg{
  height: 80px;
  width: 80px;
  margin-right: 1.5rem;
}

.featuredTitle,
.featuredDescription{
  color: var(--whitecolor);
}

.menuitems{
  display: grid;
  row-gap: 1.5rem;
}

.menuitem{
  position: relative;
  padding: 2rem 2.5rem;
  background-color: var(--whitecolor);
}

.menuitemDetails{
  margin-right: 1.5rem;
}

.menuitemTopic{
  font-weight: var(--font-semi-bold);
}

.menuitemDescription{
  font-size: var(--smallfont);
}

.menuitemPrice{
  flex-direction: column;
  padding-left: 2rem;
  border-left: 2px solid rgba(0, 0, 0, 0.3);
}
.discountprice{
  font-size: 1.8rem;
}

.realprice{
  text-decoration: line-through;
}

.timetopic{
  display: block;
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  text-transform: capitalize;
  font-weight: var(--font-regular);
  padding : 1.5rem 0rem;
  color: var(--whitecolor);
  background-color: var(--primarycolor);
}

.timelist{
  padding: 1.5rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  justify-content: space-between;
  background-color: var(--whitecolor);
}

.openday{
  text-transform: capitalize;
}

.timelist:nth-child(7){
  border-bottom: none;
}

/* styling for insta post */
.smallContainer .col_3_margin{
	transition: transform 0.5s;
	cursor: pointer;
}

.row2{
    display : flex;
    flex-wrap : wrap;
    justify-content : space-between;
}

.smallContainer .col_3_margin img{
  width: 100%;
}

.col_3_margin{
    flex-basis: 30%;
    min-width : 250px;
    position : relative;
    margin-bottom: 20px;
}

.smallContainer .col_3_margin:hover{
	transform: translateY(-10px);
}

/* reviews */
.review{
  background-color: #f2f2f2;
}

.review  .section_description,
.review .section_title{
  color: var(--textcolor);
}


.testimonial-content .reviewimage{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 2rem;
}

.testimonial-content{
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: column;
  row-gap: 2rem; 
}

.review-quote{
  padding: 0 15rem;
  text-align: center;
}

.testimonialsPersonDetails{
  flex-direction: column;
}

.testimonialsPersonDetails .name{
  font-size: 1.8rem;
  font-weight: var(--font-medium);
  text-transform: capitalize;
}

.testimonialsPersonDetails .job{
  margin : 1rem 0rem;
  text-transform: capitalize;
}

.testimonialsPersonDetails .staricon{
  color: var(--primarycolor);
}

.quoteicon{
  font-size: 3rem;
  color: var(--primarycolor);
}

.newsletter .logo-icon,
.newsletter .logo-text,
.newsletter .section_description{
  color: var(--textcolor);
}

.newsletter_container{
  display: grid;
  justify-items: center;
  row-gap: 4rem;
}

.newsletter .logo-icon{
  color: var(--textcolorlight);
  font-size: 4rem;
}

.newsletter .logo-text{
  font-size: 2rem;
  color: var(--textcolorlight);
  font-weight: var(--font-medium);
}

.newsletter-inputbox{
  position: relative;
  height: 5rem;
  max-width: 400px;
  width: 100%;
  overflow: hidden;
}

.newsletterInput{
  height: 100%;
  width: 100%;
  border-radius: 3rem;
  border: 2px solid rgba(0, 0, 0, 0.2);
  padding: 0 16rem 0 2rem;
}

.newsletter-btn{
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 3rem 3rem 0;
}

.newsletter .media{
  display: flex;
}

/* media query */
@media screen and (max-width: 1000px){
  .section_description{
    padding: 0rem 8rem;
  }
  .about-imagecontent{
    height: 400px;
  }
  .image-abouttext{
    padding: 1.5rem 2rem;
  }
  .menu .menuContents{
    grid-template-columns: 1.5fr 1fr;
    column-gap: 3rem;
  }
  .smallContainer .col_3_margin img {
    width: 100%;
  }
  .row2{
    justify-content: space-evenly;
  }
}

@media screen and (max-width : 768px){
  .section{
    padding: 7rem 1rem;
  }

  .featuredDescription,
  .review-quote{
    padding: 0rem 6rem;
  }


  .aboutcontent{
    grid-template-columns: 1fr;
    justify-items: center;
    row-gap: 3rem;
  }
  .about .content-description,
  .about .content-subtitle,
  .about .content-title{
    text-align: center;
  }
  .about-details{
    justify-items: center;
  }
  .about-lists.flex{
    justify-content: center;
  }
  .menu .menuContents{
    grid-template-columns: 1fr;
    row-gap: 3rem;
  }
  
}

@media screen and (max-width : 600px){
  .section_description{
    padding: 0rem;
  }
  .swiper{ 
    --swiper-navigation-size: darkgrey;
    --swiper-theme-color: #c87e4f;
  }
  .hometitle{
    font-size: var(--h1font);
  }
  .homesubtitle{
    margin-bottom: 1rem;
  }

}

@media screen and (max-width: 475px){
  .image-abouttext{
    max-width: 275px;
    right: -4rem;
  }

}

@media screen and (max-width: 425px){
  .about-lists{
    flex-direction: column;
    row-gap: 1rem;
  }
  .about-list.dot{
    display: none;
  }
}

@media screen and (max-width : 400px){
  .image-abouttext {
    max-width: 225px;
    padding: 1rem 2rem;
  }
  .aboutcontent{
    padding: 0rem 4rem;
  }
}

@media screen and (max-width : 375px){
  .review-quote{
    padding : 0rem 3.5rem;
  }
  .featuredDescription{
    padding: 0 3rem;
  }
  .menuitem{
    padding: 2rem 1rem;
  }
  .hometitle{
    font-size: var(--h2font);
  }
}

@media screen and (max-width : 350px){
  .aboutcontent{
    padding: 0rem 3rem;
  }
  .image-abouttext {
    max-width: 200px;
    right: -3rem;
  }
  .menuitem{
    flex-direction:  column;
    row-gap: 2rem;
  }
  .menuimg{
    margin-right: 0rem;
  }
  .menuitemDetails{
    text-align: center;
    margin-right: 0rem;
  }
  .menuitemPrice{
    padding-left: 0rem;
    border-top: 2px solid rgba(0, 0, 0, 0.3);
    border-left: none;
  }
}

@media screen and (max-width: 330px){
  .aboutcontent{
    padding: 0rem 2rem;
  }
  .about-lists{
    flex-direction: column;
    row-gap: 1rem;
  }
  .about-list.dot{
    display: none;
  }

  .about .content-description,
  .about .content-subtitle, 
  .about .content-title{
    text-align: left;
  }
  .aboutbtn{
    flex-direction: column;
    row-gap: 2rem;
  }
  .image-abouttext{
    right: -2rem;
    padding: 1rem;
  }
  .about-imagecontent{
    height: 325px;
  }
  .hometitle{
    font-size: var(--h4font);
  }
  .homesubtitle{
    font-size: var(--smallerfont);
  }

  .timelist{
    column-gap: 3rem;
  }

  span.opentime{
    text-align: right;
  }

}

</style>
