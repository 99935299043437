<template>
    <header ref="navupdate">
      <nav ref="nav" class="nav container flex">
          
          <a href="#" class="logo-content flex">
              <!-- <i class='bx bx-coffee logo-icon'></i> -->
              <span class="logo-text">Aasil-way</span>
          </a>
  
          <div class="menu-content" ref="navMenu">
              <ul class="menu-list flex">
                  <li><router-link to="/" class="nav-link active-navlink" @click="closeNav">home</router-link></li>
                  <!-- <li><router-link :to="{name: 'allPackages'}" class="nav-link" @click="closeNav">menu</router-link></li> -->
                  <!-- <li><router-link :to="{name: 'about'}"  class="nav-link" @click="closeNav">About</router-link></li> -->
                  <li><router-link :to="{name: 'reviews'}" class="nav-link" @click="closeNav">review</router-link></li>
              </ul>

              <div class="media flex">
                <a href=""><i class='bx bxl-facebook'></i></a>
                <a href=""><i class='bx bxl-twitter'></i></a>
                <a href=""><i class='bx bxl-instagram-alt'></i></a>
                <a href=""><i class='bx bxl-github'></i></a>
                <a href=""><i class='bx bxl-youtube'></i></a>
              </div>

              <i class='bx bx-x navclose-btn' @click="closeNav"></i>
          </div>

        <div class="contact-content flex">
            <i class='bx bx-phone phone-icon'></i>
            <span class="phone-number">+971 52 439 2882</span>
        </div>

        <i class='bx bx-menu navOpen-btn' @click="openNav"></i>

      </nav>
    </header>
  </template>
  
  <script>
  import { ref , onMounted } from 'vue';
  export default {
    setup(){

        const navMenu = ref(null)
        const navupdate = ref(null)
        const nav = ref(null)

        const updateScroll = () => {
            const scrollPosition = window.scrollY
            // console.log(scrollPosition)
            if(scrollPosition > 5){
              navupdate.value.classList.add('header-active')
              return;
            }else{
              navupdate.value.classList.remove('header-active')
              return;
            }
        }

        const openNav = () => {
            navMenu.value.classList.add('open')
            if(nav !== null){
                nav.value.style.overflowY = 'hidden'
            }
        }

        const closeNav = () => {
            navMenu.value.classList.remove('open')
            if(nav !== null){
                nav.value.style.overflowY = 'scroll'; 
            }
        }

        onMounted (() => {
            window.addEventListener("scroll", updateScroll)
        })

        return{navMenu , navupdate , openNav , closeNav , updateScroll , nav}
    }
  }
  </script>
  
  <style>
  .container{
    max-width: 1030px;
    width: 100%;
    margin: 0 auto;
    padding: 0 3rem;
  }
  .flex{
    display: flex;
    align-items: center;
    column-gap: 0.6rem;
  }
  header{
    position: fixed;
    top: 0;
    left: 0;
    height: 7rem;
    width: 100%;
    z-index: var(--z-fixed);
    /* box-shadow: 0 0 10px rgba(0,0,0,0.2); */
    transition: var(--trans-0-5);
  }
  .header-active{
    height: 6rem;
    background-color: var(--whitecolor);
    box-shadow: 0 0 10px rgba(0,0,0,0.2);

  }

  .nav{
    position: relative;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .nav-link{
    position: relative;
    text-transform: capitalize;
  }
  .menu-list{
    column-gap: 2rem;
  }

  .logo-icon,
  .phone-icon,
  .nav-link,
  .logo-text,
  .phone-number{
    color : rgb(161, 161, 161);
  }

  .header-active .logo-icon,
  .header-active .phone-icon,
  .header-active .nav-link,
  .header-active .logo-text,
  .header-active .phone-number{
    color: var(--textcolor);
  }

  .logo-icon,
  .phone-icon{
    font-size: 2rem;
  }

  .nav-link::before{
    content: '';
    position: absolute;
    left: 50%;
    bottom: -8px;
    height: 6px;
    width: 6px;
    border-radius: 50%;
    transform: translateX(-50%);
    background-color: var(--whitecolor);
    opacity: 0;
  }

  .active-navlink::before{
    opacity: 1;
  }
  .media a{
    color: var(--textcolorlight);
  }

  .media{
    column-gap: 1rem;
  }

  .navclose-btn,
  .navOpen-btn,
  .media{
    display: none;
  }

  @media screen and (max-width : 768px){
    .navclose-btn,
    .navOpen-btn,
    .media{
        display: block;
    }
    .nav .menu-content{
        position: fixed;
        top: 0;
        right: -100%;
        max-width: 300px;
        width: 100%;
        height: 100%;
        z-index: var(--z-fixed);
        padding: 10rem 0 5rem;
        background-color: var(--whitecolor);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        transition: var(--trans-0-5);
    }
    .nav .menu-content.open{
        right: 0;
    }
    .menu-list{
        flex-direction: column;
        row-gap: 2.5rem;
        font-size: var(--h4font);
    }
    .nav-link{
        color: var(--textcolor);
    }
    .nav-link::before{
       background-color: var(--textcolor); 
    }
    .navOpen-btn{
        font-size: 2rem;
        cursor: pointer;
        color : rgb(161, 161, 161);
    }
    .header-active .navOpen-btn{
      color: var(--textcolor);
    }
    .contact-content{
        display: none;
    }
    .navclose-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
        font-size: 2.5rem;
        cursor: pointer;
    }

    
  }

  @media screen and (max-width : 600px){
    .container{
      padding: 1.2rem;
    }
  }
  </style>