import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import reviews from '@/views/reviews/reviews.vue'
import createService from '@/views/service/createservice'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/reviews/reviews',
    name: 'reviews',
    component: reviews
  },
  {
    path: '/service/createservice',
    name: 'createService',
    component: createService
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
